/************ Custom Colors CSS ************/
:root {
  --text-color: #301B11;
  --background-color-1: #F6F2EE;
  --background-color-2: #EFE7DF;
  --main-accent-color: #CC907B;
  --accent-color-1: #E5BCAD;
  --accent-color-2: #E6D4C4;
  --accent-color-3: #F6E0D6;
}

@font-face {
  font-family: CatchyMager;
  src: url('../assets/font/CatchyMagerRegular.ttf');
  font-weight: 700;
}

@font-face {
  font-family: CocoGothicBold;
  src: url('../assets/font/CocoGothicBold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: CocoGothicRegular;
  src: url('../assets/font/CocoGothicRegular.ttf');
  font-weight: 700;
}

@font-face {
  font-family: PlayfairDisplayRegular;
  src: url('../assets/font/PlayfairDisplayRegular.ttf');
  font-weight: 700;
}

/************ Contact Me CSS ************/
.contact {
  position: relative;
  background-color: var(--background-color-2);
}

.contact h2 {
  color: var(--text-color);
  line-height: 1.5em;
  text-align: center;
  padding: 0 0 10px 4px;

  font-size: 20px;
  font-weight: 700;
  letter-spacing: 5.5px;
  font-family: 'CocoGothicBold', sans-serif;

  display: flex; 
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact h4 {
  color: var(--text-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-family: 'CatchyMager', sans-serif;

  margin-top: 10px;
  padding: 0 0 100px 0;
}

/* For mobile devices */
@media (max-width: 600px) {
  .contact .email {
    padding-right: 20% !important;
    width: 100px;
  }
  .contact .github {
    padding-right: 20% !important;
    width: 100px;
  }
  .contact .linkedin {
    width: 100px;
  }

  .contact .social-icon {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
  }
}