/************ Custom Colors CSS ************/
:root {
    --text-color: #301B11;
    --background-color-1: #F6F2EE;
    --background-color-2: #EFE7DF;
    --main-accent-color: #CC907B;
    --accent-color-1: #E5BCAD;
    --accent-color-2: #E6D4C4;
    --accent-color-3: #F6E0D6;
}

/************ Footer CSS ************/
.footer {
    padding: 0 0 50px 0;
    background-color: var(--background-color-1);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  /* Default style for all device sizes */
  .footer h3 {
    margin-top: 10%;
  }
  /* Media query for medium-sized devices (Tablets/iPad) */
  @media (max-width: 768px) {
    .footer h3 {
      margin-top: 25%;
    }
  }
  /* Media query for small-sized devices (Phones, etc.) */
  @media (max-width: 600px) {
    .footer h3 {
      margin-bottom: -5%;
      margin-top: 10%;
    }
  }
  
  .footer img {
    width: 26%;
  }
  .footer p {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-color);
    letter-spacing: 0.5px;
    margin-top: 20px;
  }