/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
/************ Custom Colors CSS ************/
:root {
  --text-color: #301B11;
  --background-color-1: #F6F2EE;
  --background-color-2: #EFE7DF;
  --main-accent-color: #CC907B;
  --accent-color-1: #E5BCAD;
  --accent-color-2: #E6D4C4;
  --accent-color-3: #F6E0D6;
}

/************ Default CSS ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: var(--background-color-1) !important;
  color: var( --text-color) !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
