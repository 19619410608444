/************ Custom Colors CSS ************/
:root {
    --text-color: #301B11;
    --background-color-1: #F6F2EE;
    --background-color-2: #EFE7DF;
    --main-accent-color: #CC907B;
    --accent-color-1: #E5BCAD;
    --accent-color-2: #E6D4C4;
    --accent-color-3: #F6E0D6;
}

@font-face {
	font-family: CatchyMager;
	src: url('../assets/font/CatchyMagerRegular.ttf');
	font-weight: 700;
}

@font-face {
	font-family: CocoGothicBold;
	src: url('../assets/font/CocoGothicBold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: CocoGothicRegular;
	src: url('../assets/font/CocoGothicRegular.ttf');
	font-weight: 700;
}

@font-face {
	font-family: PlayfairDisplayRegular;
	src: url('../assets/font/PlayfairDisplayRegular.ttf');
	font-weight: 700;
}

/************ Projects CSS ************/
.project-bx {
    background-color: var(--background-color-1);
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: 50px;
}

.project-bx-webdev {
  background-color: var(--accent-color-1);
  border-radius: 64px;
  text-align: center;
  padding: 40px 50px 20px 50px;
  margin-top: 50px;
}

.project-bx-mobiledev {
  background-color: var(--accent-color-2);
  border-radius: 64px;
  text-align: center;
  padding: 40px 50px 20px 50px;
  margin-top: 50px;
}

.project-bx-datascience {
  background-color: var(--accent-color-3);
  border-radius: 64px;
  text-align: center;
  padding: 40px 50px 20px 50px;
  margin-top: 50px;
}
  
.project {
  padding: 100px 0 100px 0;
  position: relative;
  background-color: var(--background-color-1);
  background-size: cover;
}

.project h2 {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 7.5px;
  font-family: 'CocoGothicBold', sans-serif;
}

.project p {
  color: var(--text-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 75px auto;
  text-align: center;
  /* width: 56%; */
  
  justify-content: center;
}

.project span{
  color: var(--accent-dark);
  font-weight: bold;
  font-style: italic;
}

.project h3 {
  text-align: left;
  /* font-weight: 700; */
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;

  font-size: 30px;
  font-family: 'CatchyMager', sans-serif;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
}


/************ Projects CSS (for mobile devices) ************/
@media (max-width: 767px) {
  .project p {
    width: 100%;
    text-align: center;
  }

  .project h3 {
    text-align: center;
  }

  .project-bx-webdev,
  .project-bx-mobiledev,
  .project-bx-datascience {
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}