/************ Custom Colors CSS ************/
:root {
    --text-color: #301B11;
    --background-color-1: #F6F2EE;
    --background-color-2: #EFE7DF;
    --main-accent-color: #CC907B;
    --accent-color-1: #E5BCAD;
    --accent-color-2: #E6D4C4;
    --accent-color-3: #F6E0D6;
}

@font-face {
	font-family: CatchyMager;
	src: url('../assets/font/CatchyMagerRegular.ttf');
	font-weight: 700;
}

@font-face {
	font-family: CocoGothicBold;
	src: url('../assets/font/CocoGothicBold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: CocoGothicRegular;
	src: url('../assets/font/CocoGothicRegular.ttf');
	font-weight: 700;
}

/************ Home CSS ************/
.home {
    margin-top: 0;
    /* padding: 260px 0 100px 0; */
    padding: 200px 0 200px 0;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  
    display: flex;
    justify-content: center; /* Horizontally centers the content */
  }
  .home .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }
  .home h1 {
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    display: block;

    font-size: 100px;
    font-family: 'CatchyMager', sans-serif;
  }
  .home h2 {
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;

    font-size: 50px;
    font-family: 'CatchyMager', sans-serif;
  }
  .home h3 {
    font-weight: 700;
    letter-spacing: 4px;
    line-height: 1;
    margin-bottom: 20px;
    margin-top: 30px;
    display: block;

    font-size: 20px;
    font-family: 'CocoGothicBold', sans-serif;
  }
  .home p {
    color: var( --text-color);
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }
  .home a {
    color: var(--text-color);
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
  }
  .home a svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
  }
  .home a:hover svg {
    margin-left: 25px;
  }

  .home button {
    color: var(--text-color);
    border: 1px solid var(--accent-color-1);
    padding: 18px 34px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;

    font-weight: 700;
    letter-spacing: 2.25px;

    font-size: 12px;
    font-family: 'CocoGothicBold', sans-serif;
  }
  .home button span {
    z-index: 1;
  }
  .home button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: var(--accent-color-1);
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .home button:hover {
    color: var(--text-color);
  }
  .home button:hover::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
  }

  .home img {
    /* animation: updown 3s linear infinite; */
    max-width: 75%;
    height: auto;
    display: block; 
    margin-left: auto;
  }

  .txt-rotate > .wrap {
    border-right: 0.08em solid #666;
  }
  
  .home a{
    color: var(--text-color);
    text-decoration: none;
  }
  
  .home a:hover{
    color: var(--main-accent-color);
  }

  .resume-button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.resume-button {
    padding: 10px 20px;
    /* Additional styles for the button */
}

@keyframes updown {
  0% {
      transform: translateY(-20px);
  }
  50% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(-20px);
  }
}
/* Center the image on mobile devices */
@media (max-width: 767px) {
  .home img {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%; /* Adjust this value as needed */
    height: auto;
    display: block;
  }

  .home h1 {
    font-size: 90px;
  }

  .home h2 {
    font-size: 40px;
  }

  .home h3 {
    font-size: 20px;
  }
}