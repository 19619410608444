/************ Custom Colors CSS ************/
:root {
    --text-color: #301B11;
    --background-color-1: #F6F2EE;
    --background-color-2: #EFE7DF;
    --main-accent-color: #CC907B;
    --accent-color-1: #E5BCAD;
    --accent-color-2: #E6D4C4;
    --accent-color-3: #F6E0D6;
}

@font-face {
	font-family: CatchyMager;
	src: url('../assets/font/CatchyMagerRegular.ttf');
	font-weight: 700;
}

@font-face {
	font-family: CocoGothicBold;
	src: url('../assets/font/CocoGothicBold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: CocoGothicRegular;
	src: url('../assets/font/CocoGothicRegular.ttf');
	font-weight: 700;
}

@font-face {
	font-family: PlayfairDisplayRegular;
	src: url('../assets/font/PlayfairDisplayRegular.ttf');
	font-weight: 700;
}

/************ Project Card CSS ************/
.project-card {
    background-color: var(--background-color-1);
    border-color: var(--background-color-1);
    border-radius: 64px;
    padding: 20px;

    margin-bottom: 20px;
}

.project-card .card-title {
    text-align: left;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    display: block;

    font-size: 25px;
    font-family: 'CatchyMager', sans-serif;

    margin-left: 95px;
}

.project-card .card-text {
    text-align: left;
    margin-left: 95px;

    white-space: nowrap;
    font-family: 'PlayFairDisplayRegular', sans-serif;
    line-height: 1.5;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .project-card .card-title {
        text-align: left;
        margin-left: -80px;
    }
  
    .project-card .card-text {
        text-align: left;
        margin-left: -80px;
        margin-top: 0;
    }

    .project-card .card-img {
        display: none;
      }
}

/* Media query for mobile devices */
@media (max-width: 1024px) {
    .project-card .card-title {
        text-align: left;
        margin-left: -80px;
        padding: 10px;
    }
  
    .project-card .card-text {
        text-align: left;
        margin-left: -80px;
        margin-top: 0;

        padding: 10px;
    }

    .project-card .card-img {
        display: none;
    }
}